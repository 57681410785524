
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// window.Vue = require('vue');
import Vue from "vue"

const {router} = require('./router.js');

import VModal from 'vue-js-modal'
Vue.use(VModal)

const app = new Vue({
	router: router,
    el: '#app'
});
