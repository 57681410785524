<template>
	<div id="header">
    <img src="http://www.cloakroomticket.com/shop/image/cache//garderobenmarken/logopgm-216x142-216x142.png" alt="">
	</div>
</template>
<style>

</style>
<script>
	//import GameIndex from './components/header.vue'

	export default {
		props: [],
		computed: {},
		data() {
			return {
				msg: 'hello vue'
			}
		},
		watch: {},
		components: {},
		methods: {},
		mounted: function () {
			var self = this;
		}
	}
</script>
