import VueRouter from 'vue-router'
import Vue from "vue"
Vue.use(VueRouter)


import Dashboard from './components/Base.vue'
import ModalFront from "./components/ModalFront";
import ModalBack from "./components/ModalBack";

const routes = [
	{ path: '/', component: Dashboard },
	{ path: '/front', component: ModalFront,
		props: {
			embedded: true
		}
	},
	{ path: '/back', component: ModalBack,
		props: {
			embedded: true
		}
	},
]

const router = new VueRouter({
	base: '/',
	mode: "history",
	routes // short for `routes: routes`
})

export {router};
