<template>
  <div class="modal-wrapper">
    <h2>{{ strings.GENERATE_FRONT_SIDE }} {{ downloadCode }}</h2>
    <div>
      <ul class="stepper">
        <li :class="[step == 1 ? 'active' : '']"><i>1</i> {{ strings.UPLOAD_FILE }}</li>
        <li :class="[step == 2 ? 'active' : '']"><i>2</i> {{ strings.EDIT_IMAGE }}</li>
        <li :class="[step == 3 ? 'active' : '']"><i>3</i> {{ strings.FINISH }}</li>
      </ul>
    </div>

    <div class="error-box" v-show="uploadStatus.error">
      {{ uploadStatus.message }}
    </div>

    <div class="step-wrapper" v-show="step == 1">
      <b>{{ strings.UPLOAD_SPECIFICATION }}</b>
      <p v-html="strings.FILE_SIZE"></p>

      <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        @vdropzone-success="uploadSuccess"
        @vdropzone-error="uploadError"
        @vdropzone-file-added="selectedFile"
        :options="dropzoneOptions"></vue-dropzone>
    </div>

    <div class="step-wrapper" v-show="step == 2">
      <div>{{ strings.YOU_CAN_ZOOM_IN }}</div>
      <div class="ticket-wrapper">
        <croppa v-model="myCroppa"
                :width="709"
                :height="425"
                :quality="1"
                :prevent-white-space="true"
                :show-remove-button="false"
        >
          <img slot="initial" :src="initialImageUrl" />
        </croppa>
      </div>
      <button class="btn btn-success btn-upload" @click="uploadCroppedImage()">{{ strings.SUBMIT_DESIGN }}</button>
    </div>

    <div class="step-wrapper" v-if="step == 3">
      <div class="download-code" v-html="downloadString"></div>
      <div v-if="embedded" class="buttons-wrapper">
        <button class="btn btn-default btn-upload" @click="clean" style="margin-right: 20px;">{{ strings.I_DONT_LIKE_IT }}</button>
        <button class="btn btn-success btn-upload" @click="emitCode">{{ strings.USE_THE_DESIGN }}</button>
      </div>
      <div v-else>
        <div v-html="referrenceString"></div>

        <br><br> <span>{{ strings.REF_NUM }} {{downloadCode}}</span> <br> <br>
        <button type="button" class="btn btn-success btn-upload"
                v-clipboard:copy="downloadCode"
                v-clipboard:success="copyAndClose"
                v-clipboard:error="copyAndClose">{{ strings.COPY_AND_CLOSE }}</button>
      </div>
    </div>

    <div class="step-wrapper" v-if="step == 4">
      <div class="download-code">
        {{ strings.ITEM_SET_CLOSE_WINDOW }}
      </div>
    </div>
  </div>
</template>
<style>
  .croppa-container.croppa--has-target {
    transform: scale(0.33);
    position: absolute;
    transform-origin: top left;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
  }
</style>
<script>
  import VueClipboard from 'vue-clipboard2'
  import Vue from "vue"
  Vue.use(VueClipboard);

  import UploadModalMixin from './upload-modal.mixin'

	export default {
    mixins: [UploadModalMixin],
		computed: {
      downloadString() {
        if (this.language === 'en') {
          return `You can download a preview of your customised tickets by clicking on this <a target="_blank" href="/print/front/preview/${this.downloadCode}/download">link</a>.`
        } else {
          return `Sie können eine Vorschau Ihrer individuellen Tickets herunterladen, indem Sie auf diesen <a target="_blank" href="/print/front/preview/${this.downloadCode}/download">Link</a> klicken.`
        }
      },
    },
		data() {
			return {
        dropzoneOptions: {
          url: '/generator/upload/front',
          paramName: "image",
          previewsContainer: false,
          createImageThumbnails: false,
          thumbnailWidth: 150,
          maxFilesize: 200,
          headers: {
            'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content
          },
          dictDefaultMessage: '',
        },
			}
		},
    methods: {
      uploadCroppedImage() {
        var self = this;
        this.myCroppa.generateBlob((blob) => {
          // write code to upload the cropped image file (a file is a blob)

          const formData = new FormData();
          formData.append( 'image', blob );
          formData.append( 'side', 'front' );
          formData.append( 'original', this.originalFile);

          axios.post('/generator/upload/cropped/front', formData, {
            headers: { 'content-type': 'multipart/form-data' }
          }).then((response) => {

            self.step = 3;
            this.downloadCode = response.data.uuid;

          }).catch(function(error){
            console.log(error);
          })

        }, 'image/jpeg', 1)
      },
      emitCode() {
        // Called from the iframe
        const message = JSON.stringify({
          type: 'modal-front',
          upload_id: this.downloadCode
        });
        window.parent.postMessage(message, '*');
        this.step = 4;
      },
    },
	}
</script>
