// vuejs mixin
import Croppa from "../croppa/cropper";
import vue2Dropzone from "vue2-dropzone";

export default {
    components: {
        Croppa,
        vueDropzone: vue2Dropzone,
    },
    props: {
        embedded: {
            type: Boolean,
            default: false
        },
    },
    created() {
        this.dropzoneOptions.dictDefaultMessage = this.strings.DROP_TO_UPLOAD;
    },
    computed: {
        language() {
            return this.$route.query.lang === 'en' ? 'en' : 'de';
        },
        strings() {
            return this.allStrings[this.language];
        },
        referrenceString(){
            if (this.language === 'en') {
                return `If you like it, please insert this reference number, when placing your order in our <a target="_blank" href="https://www.cloakroomticket.com/shop/">webshop</a>:`
            } else {
                return `Wenn es Ihnen gefällt, geben Sie diese Referenznummer bitte bei Ihrer Bestellung in unserem <a target="_blank" href="https://www.garderobenzettel.de/shop/">Webshop</a> ein`
            }
        }
    },
    data() {
        return {
            step: 1,
            myCroppa: {},
            initialImageUrl: null,
            uploadStatus: {
                error: 0,
                message: ''
            },
            downloadCode: '',
            allStrings: {
                de: {
                    'GENERATE_FRONT_SIDE': 'Logo hochladen und Vorderseite generieren',
                    'GENERATE_BACK_SIDE': 'Logo hochladen und Rückseite generieren',
                    'UPLOAD_FILE': 'Datei hochladen',
                    'EDIT_IMAGE': 'Bild bearbeiten',
                    'FINISH': 'Fertig',
                    'UPLOAD_SPECIFICATION': 'Bitte laden Sie Ihr Logo mit den folgenden Spezifikationen hoch:',
                    'FILE_SIZE': 'Datei Größe: 709 px х 425 px <br> DPI: 300 dpi <br> Datei Fromat: JPEG, TIFF<br>',
                    'FILE_SIZE_BACK': 'Datei Größe: 1122 px x 425 px <br> DPI: 300 dpi <br> Datei Fromat: JPEG, TIFF<br>',
                    'YOU_CAN_ZOOM_IN': 'Sie können den Bildausschnitt vergrößern, verkleinern und in alle Richtungen ziehen, um ihn neu zu positionieren.',
                    'SUBMIT_DESIGN': 'Design einreichen',
                    'ITEM_SET_CLOSE_WINDOW': 'Artikel eingestellt, Sie können dieses Fenster jetzt schließen.',
                    'I_DONT_LIKE_IT': 'Mir gefällt es nicht',
                    'USE_THE_DESIGN': 'Design verwenden',
                    'DROP_TO_UPLOAD': 'Dateien hier ziehen, um hochzuladen',
                    'COPY_AND_CLOSE': 'Kopieren und schließen',
                    'REF_NUM': 'Referenznummer',
                },
                en: {
                    'GENERATE_FRONT_SIDE': 'Generate front side',
                    'GENERATE_BACK_SIDE': 'Generate back side',
                    'UPLOAD_FILE': 'Upload file',
                    'EDIT_IMAGE': 'Edit image',
                    'FINISH': 'Finish',
                    'UPLOAD_SPECIFICATION': 'Please upload your design with the following specifications:',
                    'FILE_SIZE': 'File Size: 709 px х 425 px <br> DPI: 300 dpi <br> File Format: JPEG, TIFF<br>',
                    'FILE_SIZE_BACK': 'File Size: 1122 px x 425 px <br> DPI: 300 dpi <br> File Format: JPEG, TIFF<br>',
                    'YOU_CAN_ZOOM_IN': 'You can zoom in, zoom out and drag your image in order to reposition it.',
                    'SUBMIT_DESIGN': 'Submit design',
                    'ITEM_SET_CLOSE_WINDOW': 'Item set, you can close this window now.',
                    'I_DONT_LIKE_IT': 'I don\'t like it',
                    'USE_THE_DESIGN': 'Use the design',
                    'DROP_TO_UPLOAD': 'Drop your file here or click to upload',
                    'COPY_AND_CLOSE': 'Copy and close',
                    'REF_NUM': 'Reference number',
                }
            }
        }
    },
    methods: {
        copyAndClose(){
            this.$emit("close", {});
        },
        uploadSuccess(file, response) {
            this.initialImageUrl = response.url;
            this.originalFile = response.original;
            this.myCroppa.refresh();
            this.step = 2;
        },
        uploadError(file, message, xhr) {
            this.uploadStatus.error = 1;
            this.uploadStatus.message = message;
        },
        selectedFile() {
            this.uploadStatus.error = 0;
        },
        clean() {
            this.step = 1;
            this.downloadCode = ''
        }
    }
}
