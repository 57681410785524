<template>
  <div class="wrapper">
    <Header></Header>

    <div>
      <img class="preview" src="http://www.cloakroomticket.com/shop/image/cache//garderobenmarken/3-1024x300.jpg" alt="">
    </div>

    <div class="buttons-row">
      <button class="btn btn-success btn-lg" @click="show('generate-front')">Generate Front</button>
      <button class="btn btn-success btn-lg" @click="show('generate-back')" >Generate Back</button>
    </div>

    <div id="footer">
      <small>Copyright © 2012 - 2018 Professionelle Garderoben Marken UG (Haftungsbeschränkt). Alle Rechte vorbehalten. IT-Beratung durch IMS Group Ltd.</small>
    </div>

    <modal name="generate-front" height="auto" width="620px">
      <ModalFront @close="hide('generate-front')"></ModalFront>
    </modal>

    <modal name="generate-back" height="auto" width="620px">
      <ModalBack @close="hide('generate-back')"></ModalBack>
    </modal>
  </div>
</template>
<style scoped>
body .v--modal-overlay {
  background: rgba(0, 0, 0, 0.2);
}

body .v--modal {
  box-shadow: none;
}
</style>

<script>
  import Header from './Header.vue'
  import ModalFront from './ModalFront.vue'
  import ModalBack from './ModalBack.vue'

  export default {
    components: {
      Header,
      ModalFront,
      ModalBack
    },
    data: function () {
      return {

      }
    },
    methods: {
      show (modal) {
        this.$modal.show(modal);
      },
      hide (modal) {
        this.$modal.hide(modal);
      }
    },
    mounted() {
      this.show();
    }
  }
</script>
